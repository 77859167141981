<template>
  <Spinner v-if="isLoading"/>
  <div v-else-if="((isDetail || isEdit) && !!item) || isCreate">
    <div class="page__block mb-30">
      <VButtonPageBack />

      <div v-if="isDetail" class="additional">
        <VButton
          v-if="permissionUpdateTemplatePosition"
          text="Редактировать"
          icon="edit"
          size="sm"
          @click.native="goToPage('TemplatePositionUpdate', { id })"
        />

        <VButton
          v-if="permissionDestroyTemplatePosition"
          text="Удалить"
          icon="remove"
          size="sm"
          @click.native="openModal"
        />
      </div>
    </div>

    <VForm v-model="formData" ref="formHeader">
      <div class="column gap-m mb-30">
        <h3 v-if="isDetail" class="fw-normal">
          Тип изделия:&nbsp;{{ item.type.name }}
        </h3>

        <VSelect
          v-else
          label="Тип изделия"
          v-model="typePosition"
          width="450px"
          :allow-empty="false"
          :options="properties.product_type"
          required
        >
          <Validation for="required"/>
        </VSelect>

        <h3 v-if="isDetail" class="fw-normal">
          Артикул:&nbsp;{{ item.article }}
        </h3>

        <InputArticle v-else class="max-w-input" />

        <h3 v-if="isDetail" class="fw-normal">
          Наименование:&nbsp;{{ item.name }}
        </h3>

        <div v-else class="position">
          <VInput
            name="name"
            label="Наименование"
            required
          >
            <Validation for="required"/>
          </VInput>
        </div>
      </div>
    </VForm>

    <PositionFormDetails
      v-model="formData"
      ref="formPosition"
      class="mb-20"
      is-temptate
      :typePosition="typePosition"
      :isDisabled="isDetail"
    />

    <VInputFiles
      class="mb-30"
      v-model="formData.files"
      :is-edit="!isDetail"
      @onRemoveFile="(fileId) => ADD_FILE_TO_DELETE(fileId)"
    />

    <ButtonGroup
      v-if="!isDetail"
      class="mb-30"
      :textBtnConfirm="isEdit ? 'Сохранить' : 'Создать'"
      textBtnReject="Отмена"
      btn-size="l"
      :loading="$store.state.isSendingRequestPage"
      @confirm="templatePositionSaveHandler"
      @reject="onCancel"
    />

    <ModalConfirmation
      v-if="isModal"
      @confirm="templatePositionDeleteHandler"
      @reject="closeModal"
    />
  </div>
</template>

<script>
import ButtonGroup from '@/components/ui/ButtonGroup/ButtonGroup'
import VForm from '@/components/Form/VForm'
import ModalConfirmation from '@/components/ui/Modal/ModalConfirmation'
import PositionFormDetails from '@/components/production/position/PositionFormDetails'
import Validation from '@/components/Form/Validation'
import VButton from '@/components/simple/button/VButton'
import VButtonPageBack from '@/components/simple/button/VButtonPageBack'
import VInput from '@/components/Form/Vinput/VInput'
import VInputFiles from '@/components/Form/VInputFiles/VInputFiles'
import VSelect from '@/components/Form/VSelect'

import { mixinModal } from '@/mixins/modal/mixinModal'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { goToPage, goToPrevPage } from '@/utils/router'
import { prepareFormDataPosition } from '@/utils/production/position'
import InputArticle from '@/components/Form/Vinput/InputArticle.vue'

export default {
  name: 'TemplatePosition',
  components: {
    InputArticle,
    ButtonGroup,
    ModalConfirmation,
    VForm,
    PositionFormDetails,
    Validation,
    VButton,
    VButtonPageBack,
    VInput,
    VInputFiles,
    VSelect
  },
  mixins: [mixinModal],
  props: {
    action: String
  },

  data () {
    return {
      isDetail: false,
      isCreate: false,
      isEdit: false,
      id: null,
      formData: {
        files: []
      },
      typePosition: {
        id: 1,
        name: 'Пружины сжатия'
      }
    }
  },

  computed: {
    ...mapState('productions/template/position', {
      item: 'item',
      isLoading: 'isLoading'
    }),
    ...mapGetters({
      properties: 'records/propertiesAll'
    }),
    ...mapGetters('permission', {
      permissionUpdateTemplatePosition: 'permissionUpdateTemplatePosition',
      permissionDestroyTemplatePosition: 'permissionDestroyTemplatePosition'
    })
  },

  created () {
    this.$store.dispatch('records/getAllArticles')
  },

  async mounted () {
    this.id = parseInt(this.$route.params.id)

    if (this.id) {
      await this.loadItem(this.id)
    }

    this.changeAction()

    if ((this.isDetail || this.isEdit) && this.item) {
      this.syncData()
    }
  },

  destroyed () {
    this.RESET_FILES_TO_DELETE()
  },

  watch: {
    action () {
      this.changeAction()
    },

    typePosition () {
      if (this.isUpdate || this.isCreate) {
        this.clearFormData()
        this.formData = {
          files: []
        }
      }
    }
  },

  methods: {
    ...mapMutations('productions/template/position', {
      DELETE_ALL: 'DELETE_ALL',
      ADD_FILE_TO_DELETE: 'ADD_FILE_TO_DELETE',
      RESET_FILES_TO_DELETE: 'RESET_FILES_TO_DELETE'
    }),
    ...mapActions('productions/template/position', {
      loadItem: 'loadItem',
      createItemWithFiles: 'createItemWithFiles',
      updateItemWithFiles: 'updateItemWithFiles',
      deleteItem: 'deleteItem',
      syncAll: 'syncAll'
    }),
    goToPage,
    onCancel () {
      if (this.isCreate) {
        return goToPrevPage()
      }
      this.syncData()
      this.RESET_FILES_TO_DELETE()
      return goToPage('TemplatePositionDetail', { id: this.id })
    },

    // TODO PEREDELAT'
    changeAction () {
      if (this.action === 'detail') {
        this.isEdit = false
        this.isDetail = true
      }
      if (this.action === 'create') {
        this.isCreate = true
      }
      if (this.action === 'update') {
        this.isDetail = false
        this.isEdit = true
      }
    },

    async templatePositionSaveHandler () {
      let error = false
      if (this.$refs.formHeader.hasValidationErrors()) {
        error = true
      }
      if (this.$refs.formPosition.$refs.positionFormRef.hasValidationErrors()) {
        error = true
      }
      if (error) return

      const data = {
        ...prepareFormDataPosition(this.formData),
        type_id: this.typePosition.id
      }

      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_PAGE')

        const response = this.isCreate ?
          await this.createItemWithFiles({ data }) : await this.updateItemWithFiles({ id: this.id, data })
        if (!response) return

        this.id = response.data?.id
        await this.loadItem(this.id)
        this.formData = this.item

        // обновление главного массива с шаблонами
        this.syncAll({
          data: this.item,
          isCreate: this.isCreate
        })

        await goToPage('TemplatePositionDetail', { id: this.id })
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_PAGE', false)
      }
    },

    async templatePositionDeleteHandler () {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')

        const response = await this.deleteItem(this.id)
        if (response?.status !== 204) return

        // удаление из массива с шаблонами
        this.DELETE_ALL(this.id)
        await goToPage('TemplatePositionList')
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
      }
    },

    syncData () {
      this.formData = Object.assign({}, this.formData, this.item)
      this.typePosition = this.item?.type
    },
    clearFormData () {
      this.$refs.formHeader.clear()
      this.$refs.formPosition.$refs.positionFormRef.clear()
    }
  }
}
</script>
